import { Block } from 'webiq.boilerplate'

export default class CustomProductImage extends Block {
	static title = 'product-image'

	init() {
		if (this.element.classList.contains('product-image--thumb')) {
			this.bindThumbnail()
		}
	}

	bindThumbnail() {
		this.element.addEventListener('click', () => {
			this.eventEmitter.emit('product-image:thumb-click', {
				colorId: this.element.dataset.colorId,
				index: this.element.dataset.index,
			})
			this.element.classList.add('product-image--active')
		})
		this.eventEmitter.on('product-image:thumb-click', obj => {
			if (obj.colorId === this.element.dataset.colorId && obj.index === this.element.dataset.index) {
				return
			}
			this.element.classList.remove('product-image--active')
		})
		this.eventEmitter.on('color:change', obj => {
			if (obj.colorId === this.element.dataset.colorId) {
				this.element.classList.remove('product-image--hidden')
				return
			}
			this.element.classList.add('product-image--hidden')
		})
		this.eventEmitter.on('carousel:product-slide', obj => {
			if (obj.colorId !== this.element.dataset.colorId) {
				return
			}
			if (obj.index !== parseInt(this.element.dataset.index, 10)) {
				this.element.classList.remove('product-image--active')
				return
			}
			this.element.classList.add('product-image--active')
		})
	}
}
