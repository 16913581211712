import { Carousel } from 'webiq.boilerplate'

export default class CustomCarousel extends Carousel {
	init() {
		super.init()

		this.eventEmitter.on('product-image:thumb-click', obj => {
			if (obj.colorId === this.element.dataset.colorId) {
				this.swiper.slideTo(obj.index)
			}
		})

		this.eventEmitter.on('color:change', obj => {
			if (obj.colorId === this.element.dataset.colorId) {
				this.element.classList.remove('carousel--hidden')
				this.swiper.update()
				return
			}
			this.element.classList.add('carousel--hidden')
		})

		if (this.element.classList.contains('carousel--product')) {
			this.productCarousel()
		}
	}

	productCarousel() {
		this.swiper.on('slideChangeTransitionEnd', () => {
			const currentSlide = this.element.querySelector('.swiper-slide-active')
			this.eventEmitter.emit('carousel:product-slide', {
				index: parseInt(currentSlide.dataset.swiperSlideIndex, 10) + 1,
				colorId: this.element.dataset.colorId,
			})
		})
	}
}
