import 'babel-polyfill'
import 'cookieconsent'
import './bootstrap.scss'

import {
	Accordion,
	Nav,
	SubNav,
	MobileNav,
	MdcTextField,
	MdcSelect,
	Tabs,
	Wishlist,
	Form,
	Overlay,
	ScrollButton,
	HCaptcha,
	Message,
	ShoppingcartPopupButton,
	ShoppingcartPopup,
	ShoppingcartProduct,
	ShoppingcartAddressSection,
	Address,
	Orders,
	Search,
	AccountNav,
	Lightbox,
} from 'webiq.boilerplate'

import run from 'webiq.boilerplate'

import MobileFilterButton from './js/MobileFilterButton'
import MobileFilter from './js/MobileFilter'

// Custom blocks
import CustomProductInfo from './js/CustomProductInfo'
import CustomProductImage from './js/CustomProductImage'
import CustomCarousel from './js/CustomCarousel'
import DatePicker from './js/DatePicker'

run({
	Accordion,
	SubNav,
	Nav,
	MobileNav,
	MdcTextField,
	MdcSelect,
	Tabs,
	Wishlist,
	Form,
	MobileFilterButton,
	MobileFilter,
	Overlay,
	ScrollButton,
	HCaptcha,
	Message,
	Search,
	Lightbox,

	// Shoppingcart blocks
	ShoppingcartPopupButton,
	ShoppingcartPopup,
	ShoppingcartProduct,
	ShoppingcartAddressSection,
	Address,
	Orders,
	AccountNav,
	DatePicker,

	// Custom
	CustomProductInfo,
	CustomProductImage,
	CustomCarousel,
})
