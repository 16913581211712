import { Block } from 'webiq.boilerplate'
import datepicker from 'js-datepicker'

export default class DatePicker extends Block {
	static title = 'date-picker'

	init() {
		const validDate = d => d instanceof Date && !isNaN(d)
		const getDate = d => `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`

		const picker = datepicker(`#${this.element.dataset.id}`, {
			onSelect: instance => {
				if (typeof instance.dateSelected !== 'undefined') {
					this.element.value = getDate(instance.dateSelected)
				}
			},
			disabler: date => date.getDay() == 0,
			startDay: 1,
			minDate: new Date(this.element.dataset.min),
			customDays: ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'],
			customMonths: [
				'Januari',
				'Februari',
				'Maart',
				'April',
				'Mei',
				'Juni',
				'Juli',
				'Augustus',
				'September',
				'Oktober',
				'November',
				'December',
			],
			// disabledDates: [new Date(2021, 11, 31)],
			id: this.element.dataset.id,
			dateSelected:
				validDate(new Date(this.element.value)) &&
				new Date(this.element.value).getTime() > new Date(this.element.dataset.min).getTime()
					? new Date(this.element.value)
					: undefined,
		})
	}
}
