import { Popup } from 'webiq.boilerplate'

export default class MobileFilter extends Popup {
	static title = 'mobile-filter'

	init() {
		this.closeButton = this.getElement('button--close')
		if (this.closeButton) {
			this.closeButton.addEventListener('click', () => this.toggle())
		}

		this.eventEmitter.on('mobile-filter-button:toggle', () => this.toggle())
	}
}
