import { ProductInfo } from 'webiq.boilerplate'

export default class CustomProductInfo extends ProductInfo {
	static title = 'product-info'

	init() {
		super.init()
		this.optionSelect = this.element.querySelector('select[name=option_id]')
		if (this.optionSelect) {
			// set default value
			this.optionChange(this.optionSelect[this.optionSelect.selectedIndex].value)

			this.optionSelect.addEventListener('change', () =>
				this.optionChange(this.optionSelect[this.optionSelect.selectedIndex].value)
			)
		}
	}

	optionChange(optionId) {
		this.productPayload.optionId = optionId
	}

	addToShoppingcart() {
		let payload = new FormData()
		payload.append('ajax', true)
		payload.append('product_id', this.productPayload.productId)
		if (this.productPayload.optionId) {
			payload.append('option_id', this.productPayload.optionId)
		}
		payload.append('add', true)

		fetch('/shoppingcart', {
			method: 'POST',
			body: payload,
			credentials: 'same-origin',
		})
			.then(response => response.json())
			.then(json => this.handleAddResponse(json))
			.catch(error => this.handleError(error))
	}
}
